



















































import {Component, Prop, Vue} from "vue-property-decorator";
import {ChecklistElement, ElementSelection, ElementStatus} from "@/models/checklistElement";

@Component({
  components: {
    ChecklistRadio: () => import('./../components/radio/ChecklistRadio.component.vue'),
  }
})
export default class ChecklistTableRowComponent extends Vue {
  @Prop({
    default: () => {
    }
  })
  public checklistItem!: ChecklistElement;

  @Prop({
    default: () => {
    }
  })
  public actuator!: string;

  public oilList = [
    'Fahlke eco Fluid 4870',
    'Shell Tellus S4 VX 32',
    'SHELL AERO FLUID 41',
    'SHELL NATURELL HF-E 15',
    'PANOLIN HLP SYNTH 15',
    'PANOLIN HLP SYNTH 32',
    'Siehe Bemerkung'];

  public ElementSelection = ElementSelection;

  public radioChanged(elementStatus: ElementStatus) {
    this.checklistItem.elementStatus = elementStatus;
  }

}
